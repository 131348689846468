/** @jsx jsx */
// import React from "react"
import { jsx } from 'theme-ui'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Box, Heading, Text } from '../components/ui'
import SlackSignupForm from '../components/SlackSignupForm'

const MembersPage = () => (
  <Layout>
    <SEO title="Members" />
    <Box variant="layout.section">
      <Heading>Members</Heading>
      <Text>
        This section of the site contains information for Tai Chi USA students
        and teachers.
      </Text>
      <Heading>Schedule and Signups</Heading>
      Due to the pandemic, our schedule of classes is more complex than usual,
      combining online, outdoor, and indoor classes.
      <Text>
        <a
          href="https://docs.google.com/document/d/1-hrgSEFChJzz6ZTITbGTP5V3pTuPudG41ModNM8LQyg/edit?usp=sharing"
          target="_blank"
          rel="noopener noreferrer"
        >
          View the Schedule
        </a>
      </Text>
      <Text>
        <a
          href="https://docs.google.com/spreadsheets/d/10C4jICBFBwEads0AZuV-Y8hVE4A1CjxYJ4ccpP4dd2s/edit?usp=sharing"
          target="_blank"
          rel="noopener noreferrer"
        >
          View the Indoor Class Signup Sheet
        </a>
      </Text>
      <Heading>Join our Slack Community</Heading>
      <Text>
        Slack is an online community where you can talk to your fellow Tai Chi
        students and teachers:
      </Text>
      <SlackSignupForm />
    </Box>
  </Layout>
)

export default MembersPage
