/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Button } from './ui'

const url =
  'https://join.slack.com/t/taichiusa/shared_invite/zt-ev55vg4y-mVYqqWHhphJtU_NtdHMuDQ'

const SlackSignupForm = () => {
  const onClick = () => {
    console.log('click')
    window.open(url, '_blank')
  }

  return <Button onClick={onClick}>Join TaiChiUSA Slack Community</Button>
}

export default SlackSignupForm
